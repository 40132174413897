// @ts-nocheck
import qs from 'qs';
import { decamelizeKeys } from 'humps';
import axiosClient from "../axiosClient";

const exportXlsx = (url: string) => (search: any, refetch?: any) => {
  axiosClient
    .get('/export/generate_token')
    .then(({ export_token }) => {
      const searchQuery = qs.stringify(
        { ...decamelizeKeys(search || {}), token: export_token },
        { arrayFormat: 'brackets' },
        );
      window.open(`${url}.xlsx?${searchQuery}`);
      if (refetch) refetch();
    });
};

export default exportXlsx;
